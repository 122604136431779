<template>
  <svg viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1H17.9315"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M5 7.4502H18"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M1.07031 13.9004H18.0018"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
